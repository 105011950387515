import React from "react"
import { Helmet } from "react-helmet"
import { ChevronRight20, ChevronLeft20, Checkmark20 } from "@carbon/icons-react"

const LoginDemoPage = () => {
  return (
    <main className="p-2 lg:p-10 flex justify-center align-middle">
      <Helmet
        htmlAttributes={{ lang: "de" }}
        bodyAttributes={{
          class: "theme-default h-screen bg-gray-100",
        }}
      ></Helmet>
      <section className="p-2 md:p-10 w-full md:w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/4 border border-gray-50 bg-white shadow-lg">
        <header className="flex flex-col pb-10">
          <div className="block w-full h-14 px-4 pt-4 pb-2 mb-5 text-center">
            <img
              className="inline-block w-auto h-full"
              src="https://relaunch.rothenberger.com/ro-logo.svg"
              alt="Rothenberger"
              title="Rothenberger"
            />
          </div>
          <div className="block w-full text-gray-800 text-sm pt-5 border-t border-gray-50">
            <p className="pb-5">
              Willkommen bei der ROTHENBERGER-Nutzer-Registrierung.
            </p>
            <p className="text-gray-300 text-sm">
              Alle mit * markierten Felder sind erforderlich.
            </p>
          </div>
        </header>

        <div className="relative pb-5">
          <input
            className="w-full h-12 py-4 px-4 placeholder-gray-300 text-sm bg-white text-gray-800 border border-gray-100"
            type="text"
            name="email"
            value="email@example.com"
          />
          <Checkmark20 className="absolute inline-block top-3 right-3 text-primary" />
        </div>
        <div className="pb-5">
          <input
            className="w-full h-12 py-4 px-4 mb-5 placeholder-gray-300 text-sm bg-white text-gray-800 border border-gray-100"
            type="text"
            name="vorname"
            placeholder="Vorname *"
          />
          <input
            className="w-full h-12 py-4 px-4 mb-5 placeholder-gray-300 text-sm bg-white text-gray-800 border border-gray-100"
            type="text"
            name="nachname"
            placeholder="Nachname *"
          />
          <input
            className="w-full h-12 py-4 px-4 mb-5 placeholder-gray-300 text-sm bg-white text-gray-800 border border-gray-100"
            type="password"
            name="password"
            placeholder="Ihr Passwort *"
          />
          <input
            className="w-full h-12 py-4 px-4 mb-5 placeholder-gray-300 text-sm bg-white text-gray-800 border border-gray-100"
            type="password"
            name="password_repeat"
            placeholder="Bitte Passwort wiederholen *"
          />
          <input
            className="w-full h-12 py-4 px-4 placeholder-gray-300 text-sm text-gray-800 border border-gray-100"
            type="text"
            name="password"
            placeholder="Ihr Passwort"
          />
        </div>

        <div className="flex justify-center">
          <div className="w-2/3">
            <a href="#" className="block text-primary text-sm py-3">
              <ChevronLeft20 className="inline-block" /> Zurück
            </a>
          </div>
          <button className="w-1/3 h-12 btn btn-primary">
            Registrieren <ChevronRight20 className="inline-block" />
          </button>
        </div>

        <footer className="pt-5 mt-10 border-t border-gray-50">
          <span className="block w-full text-center text-xs text-gray-200">
            © {new Date().getFullYear()} ROTHENBERGER Werkzeuge GmbH
          </span>
        </footer>
      </section>
    </main>
  )
}
export default LoginDemoPage
